import { navigate } from 'gatsby';
import React, { FC, useEffect } from 'react';

const EGiftPage: FC = () => {
  useEffect(() => {
    navigate('/e-gift/template-message');
  }, []);

  return <div />;
};

export default React.memo(EGiftPage);
